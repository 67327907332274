<!--
 * @Author: 刘格优
 * @Date: 2019-11-13 17:31:30
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-03-16 18:08:15
 -->

<template>
  <div class="allcontent">
    <div class="clicontent">
      <div id="topbox">
        <div class="topback"
             :style="`backgroundColor: ${colorprimary};`">
          <div class="top clearfix">
            <img class="fl headimg"
                 :src="form.headimg" />
            <div class="name fl">
              <div>{{ form.name }}</div>
              <div>已经成为你的客户{{ form.day }}天</div>
            </div>
            <div class="fr setmid">
              <img src="@/assets/abt/img/edit.png"
                   alt
                   @click="editinfo" />
            </div>
            <div class="labbox">
              <div :class="{ labtit: true, labact: form.labelList[0] }">{{ form.labelList[0] ? form.labelList[0].labname : "添加标签" }}</div>
              <div :class="{ labtit: true, labact: form.labelList[1] }">{{ form.labelList[1] ? form.labelList[1].labname : "添加标签" }}</div>
              <div :class="{ labtit: true, labact: form.labelList[2] }">{{ form.labelList[2] ? form.labelList[2].labname : "添加标签" }}</div>
              <div class="addlab"
                   @click="getbq">
                <img src="@/assets/abt/img/addbq.png"
                     alt />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul v-if="form"
          class="infobox">
        <li class="list_item khinfobox">
          <div class="item_input"
               @click="show = !show">
            <span>客户信息</span>
            <md-icon name="arrow-down"
                     size="lg"
                     v-if="!show"></md-icon>
            <md-icon name="arrow-up"
                     size="lg"
                     v-else></md-icon>
          </div>
        </li>
        <li class="data"
            :class="{ close: !show }">
          <ul>
            <li class="list_item"
                v-if="form.mobile">
              <div class="item_content">
                <div class="item_title">
                  <span class="leftlabel">电话</span>
                  <span>{{ form.mobile }}</span>
                </div>
              </div>
            </li>
            <li class="list_item"
                v-if="form.cardno">
              <div class="item_content">
                <div class="item_title">
                  <span class="leftlabel">证件号码</span>
                  <span>{{ form.cardno }}</span>
                </div>
              </div>
            </li>
            <li class="list_item"
                v-if="form.address">
              <div class="item_content">
                <div class="item_title">
                  <span class="leftlabel">地址</span>
                  <span>{{ form.address }}</span>
                </div>
              </div>
            </li>
            <li class="list_item lastitem"
                v-if="form.intime">
              <span>从{{ form.intime.substr(0, 10) }}成为你的客户</span>
            </li>
          </ul>
        </li>
      </ul>
      <md-tab-bar class="tabbar"
                  v-model="current"
                  :items="options"
                  @change="onChange"
                  :inkcolor="colorprimary" />
    </div>
    <div class="tabcontent">
      <!-- 人脉 -->
      <div v-show="current == '1'">
        <analysis ref="chart1"
                  :userid="userid"></analysis>
      </div>
      <!-- AI分析 -->
      <div v-show="current == '2'">
        <aiana :userid="userid"
               ref="chart2"></aiana>
      </div>
      <!-- 互动 -->
      <div v-show="current == '3'"
           style="height:100%">
        <interact ref="chart3"
                  :userid="userid"></interact>
      </div>
      <!-- 跟进 -->
      <div v-show="current == '4'">
        <follow ref="chart4"
                :userid="userid"></follow>
      </div>
    </div>
    <!-- 选择标签弹框 -->
    <md-popup v-model="bqchoose"
              position="bottom"
              :mask-closable="false">
      <md-popup-title-bar title="选择标签"
                          describe
                          ok-text="确定"
                          cancel-text="取消"
                          large-radius
                          @confirm="tochoose()"
                          @cancel="hidePopUp()"></md-popup-title-bar>
      <div class="md-example-popup md-example-popup-bottom">
        <div class="list">
          <div class="bqbox"
               v-for="(item, index) in bqlist"
               :key="index">
            <p>{{ item.tagname }}</p>
            <div class="bqdiv"
                 :style="`color: ${colorprimary};`">
              <span v-for="(eve, i) in item.children"
                    :key="i"
                    @click="chselabes(eve)"
                    :class="{ active: eve.show }"
                    :style="eve.show==true?`color:white;backgroundColor: ${colorprimary};`:`border: 0.01rem solid ${colorprimary}`">{{ eve.tagname }}</span>
              <span v-show="item.tagname == '其他'"
                    @click="addlabname = true">+</span>
            </div>
          </div>
        </div>
      </div>
    </md-popup>
    <!-- 编辑客户信息 -->
    <md-popup class="clearfix"
              id="searchPage"
              v-model="bjVisible"
              transition="md-zoom">
      <div class="md-example-popup md-example-popup-bottom">
        <div class="listbox">
          <md-field>
            <md-input-item v-model="form.name"
                           title="姓名"
                           placeholder="请输入姓名"
                           is-amount></md-input-item>
            <md-field-item title="性别"
                           solid>
              <md-radio name="2"
                        v-model="form.sex"
                        label="男"
                        inline />
              <md-radio name="1"
                        v-model="form.sex"
                        label="女"
                        inline />
            </md-field-item>
            <md-input-item v-model="form.mobile"
                           title="手机号"
                           placeholder="请输入手机号"
                           is-amount></md-input-item>
            <md-field-item title="证件类型"
                           :content="selectorValue"
                           @click="showSelector"
                           class="zjpick"
                           arrow
                           solid />
            <md-input-item v-model="cardno"
                           title="证件号码"
                           placeholder="请输入证件号码"
                           is-amount></md-input-item>
            <md-input-item v-model="form.birthdate"
                           title="出生日期"
                           placeholder="请输入出生日期"
                           is-amount>
              <img @click="open()"
                   src="@/assets/abt/img/time.png"
                   style="width:30px;"
                   slot="right" />
            </md-input-item>
            <md-field-item title="婚姻状况"
                           solid
                           class="radiobox">
              <md-radio-group v-model="form.ismarried">
                <md-radio-box name="0">未婚</md-radio-box>
                <md-radio-box name="1">已婚</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-field-item title="子女个数"
                           solid
                           class="radiobox">
              <md-radio-group v-model="form.childnum">
                <md-radio-box name="0">暂无</md-radio-box>
                <md-radio-box name="1">1个</md-radio-box>
                <md-radio-box name="2">2个</md-radio-box>
                <md-radio-box name="3">3个</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <!-- <md-field-item title="家用轿车"
                           solid
                           class="radiobox">
              <md-radio-group v-model="form.cars">
                <md-radio-box name="0">暂无</md-radio-box>
                <md-radio-box name="1">1辆</md-radio-box>
                <md-radio-box name="2">2辆</md-radio-box>
                <md-radio-box name="3">3辆</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-field-item title="住房套数"
                           solid
                           class="radiobox">
              <md-radio-group v-model="form.houses">
                <md-radio-box name="0">暂无</md-radio-box>
                <md-radio-box name="1">1套</md-radio-box>
                <md-radio-box name="2">2套</md-radio-box>
                <md-radio-box name="3">3套</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-input-item v-model="form.incomef"
                           title="家庭年收入"
                           placeholder="如：5-10万"
            is-amount></md-input-item>-->
            <md-input-item v-model="form.address"
                           title="家庭地址"
                           placeholder="请输入家庭地址"
                           is-amount></md-input-item>
            <md-input-item v-model="form.company"
                           title="工作单位"
                           placeholder="请输入工作单位"
                           is-amount></md-input-item>
            <md-textarea-item title="其他情况"
                              autosize
                              class="example"
                              v-model="form.remark"
                              placeholder="请输入其他相关情况" />
          </md-field>
        </div>
        <div class="btnbox">
          <div class="cancel"
               :style="`color: ${colorprimary};border :1px solid ${colorprimary} `"
               @click="bjVisible = false">取消</div>
          <div class="savebtn"
               :style="`backgroundColor: ${colorprimary};`"
               @click="savekuinfo">保存</div>
        </div>
      </div>
    </md-popup>
    <md-selector class="datepick"
                 v-model="isSelectorShow"
                 :data="zjlist"
                 max-height="320px"
                 title="选择证件类型"
                 large-radius
                 @choose="onSelectorChoose"></md-selector>
    <md-date-picker ref="datePicker"
                    v-model="isDatePickerShow"
                    type="custom"
                    title="选择出生日期"
                    large-radius
                    :custom-types="['yyyy', 'MM', 'dd']"
                    :min-date="minDate"
                    :default-date="new Date()"
                    @confirm="onDatePickerConfirm"
                    class="datepick"></md-date-picker>
    <!-- 添加自定义标签 -->
    <md-dialog title="添加自定义标签"
               :closable="true"
               v-model="addlabname"
               :btns="basicDialog.btns"
               class="bqdialog">
      <input placeholder="请输入你要自定义的标签"
             v-model="labelname"
             class="bqtable" />
    </md-dialog>
    <!-- <Consult @tozxzx="tozxzx"></Consult> -->
    <!-- <div class="tozxzx">
      <img :src="getStorage('ossurl','')+getStorage('imgs',{}).zxicon"
           alt
           @click="tozxzx" />
    </div> -->
  </div>
</template>

<script>
import interact from "./interact/index";
import aiana from "./aiana/index";
import follow from "./follow/index";
import analysis from "./analysis/index";
import { getStorage } from "@/lib/util";
import {
  selectKHBQ,
  getCustomMessage,
  insertCustomBasemsg,
  insertCustomLabel,
  insertKHLabel
} from "@/api/abt/customerOperation/clientsort/index";
import { selectMJ } from "@/api/abt/customerOperation/common/index";
import { Toast } from "mand-mobile";
import { cloneDeep } from "lodash";
// import Consult from '@/views/abt/customerOperation/common/consulation/index';
export default {
  components: {
    interact,
    aiana,
    follow,
    // Consult,
    analysis
  },
  data () {
    return {
      colorprimary: "",
      show: false,
      bqchoose: false,
      addlabname: false,
      bjVisible: false,
      isDatePickerShow: false,
      isSelectorShow: false,
      bqlist: [],
      zjlist: [],
      labelname: "",
      userid: "",
      selectorValue: "",
      user: {},
      empno: "",
      minDate: new Date("1940/1/1"),
      form: {
        day: 0,
        headimg: "",
        name: "",
        intime: "",
        labelList: [],
        sex: "",
        mobile: "",
        cardtype: "",
        cardno: "",
        birthdate: "",
        ismarried: "",
        childnum: "",
        address: "",
        company: "",
        remark: ""
      },
      cardno: "",
      qttype: "",
      current: 1,
      options: [
        { name: 1, label: "人脉" },
        { name: 2, label: "AI分析" },
        { name: 3, label: "互动" },
        { name: 4, label: "跟进" }
      ],
      basicDialog: {
        open: false,
        btns: [
          {
            text: "取消",
            handler: this.onBasicCancel
          },
          {
            text: "确认",
            handler: this.onBasicConfirm
          }
        ]
      }
    };
  },
  watch: {
    cardno (newval) {
      if (!newval) {
        return;
      }
      let sfznum = newval.split("");
      if (sfznum.length == "18") {
        this.form.birthdate =
          newval.substr(6, 4) +
          "-" +
          newval.substr(10, 2) +
          "-" +
          newval.substr(12, 2);
      }
    },
    current (v) {
      if (v == "2") {
        this.$nextTick(_ => {
          // console.log(this.$refs[`chart${v}`])
          this.$refs[`chart${v}`].$refs[`chart${v}`].echarts.resize();
          this.$refs[`chart${v}`].$refs["chart1"].echarts.resize();
          this.$refs[`chart${v}`].$refs["chart3"].echarts.resize();
          this.$refs[`chart${v}`].$refs["chart4"].echarts.resize();
          this.$refs[`chart${v}`].$refs["chart5"].echarts.resize();
        });
      }
    }
  },

  created () {
    this.colorprimary = getStorage("theme", "");
    this.userid = this.$route.query.customer;
    this.user = getStorage("u_s", {});
    // this.userid = '10000016'
    this.getkhinfo();
  },

  mounted () {
    $(document).on("focusout", function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0);
      }, 300);
    });
  },

  methods: {
    getkhinfo () {
      getCustomMessage({ userid: this.userid }).then(res => {
        if (res.data.data) {
          this.form = res.data.data;
          if (typeof (this.form.childnum) != 'undefined') {
            this.form.childnum = String(this.form.childnum);
          }
          if (this.form.birthdate) {
            this.form.birthdate = this.form.birthdate.substr(0, 10);
          }
          this.cardno = this.form.cardno;
        }
        this.getzjtype(this.form.cardtype);
      });
    },
    savekuinfo () {
      let data = cloneDeep(this.form);
      data.userid = this.userid;
      data.cardno = this.cardno;
      delete data.labelList;
      insertCustomBasemsg(data).then(res => {
        Toast.info("编辑成功");
        this.getkhinfo();
        this.bjVisible = false;
      });
    },
    editinfo () {
      this.bjVisible = true;
      this.getkhinfo();
    },
    getzjtype (type) {
      selectMJ({ list: ["RSCARD"] }).then(res => {
        this.zjlist = res.data.data[0].value;
        this.zjlist.forEach((item, index) => {
          item.value = item.code;
          item.text = item.name;
          if (type === item.code) {
            this.selectorValue = item.name;
          }
        });
      });
    },
    tochoose () {
      let surebq = [];
      this.bqlist.forEach((item, index) => {
        item.children.forEach((ite, i) => {
          if (ite.show) {
            let bqitem = {
              labid: ite.id,
              labtype: ite.parentId
            };
            surebq = surebq.concat(bqitem);
          }
        });
      });
      insertKHLabel({ userid: this.userid, array: surebq }).then(res => {
        Toast.info("添加成功");
        this.getkhinfo();
        this.bqchoose = false;
      });
    },
    getbq () {
      selectKHBQ().then(res => {
        this.bqlist = res.data.data;
        this.bqlist.forEach((item, index) => {
          if (item.tagname === "其他") {
            this.qttype = item.id;
          }
          item.children.forEach((ite, i) => {
            this.$set(ite, "show", false);
            if (this.form.labelList.length != "0") {
              this.form.labelList.forEach((i, ind) => {
                if (ite.id == i.labcode) {
                  this.$set(ite, "show", true);
                }
              });
            }
          });
        });
        this.bqchoose = true;
      });
    },
    hidePopUp (type) {
      this.bqchoose = false;
    },
    chselabes (item) {
      item.show = !item.show;
    },
    onBasicConfirm () {
      insertCustomLabel({
        userid: this.userid,
        labtype: this.qttype,
        labname: this.labelname
      }).then(res => {
        Toast.info("添加成功");
        this.getbq();
        this.addlabname = false;
      });
    },
    onBasicCancel () {
      this.addlabname = false;
    },
    open () {
      this.isDatePickerShow = true;
    },
    onDatePickerConfirm (columnsValue) {
      this.form.birthdate = this.$refs.datePicker.getFormatDate("yyyy-MM-dd");
    },
    showSelector () {
      this.isSelectorShow = true;
    },
    onSelectorChoose ({ value, text }) {
      this.selectorValue = text;
      this.form.cardtype = value;
    },
    onChange (item, index, prevIndex) {
      // Toast.info(`index: ${index}, prevIndex: ${prevIndex}, label: ${item.label}`)
    },
    // tozxzx () {
    //   this.$router.push(
    //     `/Consult?askuserid=${this.userid}&empno=${this.user.empno}&emprytype=${this.user.rytype}`
    //   );
    // }
  }
};
</script>
<style scoped lang="stylus">
@import './index.styl';
</style>
